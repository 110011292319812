<template>
    <div class="auth-wrapper auth-v1"
         style="padding: 0;">
        <div class="auth-inner main-container-width">
            <v-row>
                <v-col cols="12"
                       sm="12"
                       md="12"
                       lg="12"
                       class="mx-auto">
                    <v-card flat
                            class="full-min-height pb-10">
                        <app-header :details="selectedCard"></app-header>
                        <v-row class="mx-lg-10 mx-md-8 mx-sm-4 mx-3 mt-9">
                            <v-col cols="12"
                                   lg="12">
                                <!-- <v-row class="backbtn mr-4 pb-5">
                                    <BackAction :text="text"
                                                :output="output" />
                                </v-row> -->
                                <p style="font-size: 18px;"
                                   class="text-black font-weight-semibold mt-5">
                                    {{ $t('Alerts & Notifications') }}
                                </p>
                            </v-col>
                        </v-row>
                        <div class="alerts mx-lg-12 mx-md-8 mx-sm-4 mx-3">
                            <v-row class="pa-4">
                                <v-col v-for="(item, index) in records"
                                       :key="index"
                                       cols="12"
                                       lg="4"
                                       md="6"
                                       sm="12">

                                    <v-card class="pa-4">
                                        <v-row>
                                            <v-col cols="12"
                                                   sm="12"
                                                   md="12"
                                                   lg="10">
                                                <p style="font-size: 13px;margin-bottom: 2px;"
                                                   class="text-black font-weight-semibold">
                                                    {{ $t(getTitle(item)) }}
                                                </p>
                                                <p style="font-size: 12px;">
                                                    {{ $t(getSubTitle(item)) }}
                                                </p>
                                            </v-col>
                                            <v-col cols="12"
                                                   sm="12"
                                                   md="12"
                                                   lg="2"
                                                   dir="ltr"
                                                   class="d-flex justify-center">
                                                <div v-if="item.NotificationStatus == 'N'"
                                                     :class="{ 'pt-4 ml-3': selectedLanguage == 'ar', 'pt-4 mr-3': selectedLanguage == 'en' }">
                                                    <label class="switch">
                                                        <input :disabled="true"
                                                               v-model="checked"
                                                               type="checkbox">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <div v-if="item.NotificationStatus == 'Y'"
                                                     :class="{ 'pt-4 ml-3': selectedLanguage == 'ar', 'pt-4 mr-3': selectedLanguage == 'en' }">
                                                    <label class="switch">
                                                        <input :disabled="true"
                                                               v-model="checked1"
                                                               type="checkbox">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-card>
                                </v-col>
                                <!-- <v-col>
                                    <div class="my-5 balance-card-background text-center"
                                         v-if="records.length == 0">
                                        <div class="no-offers">
                                            <img src="@/assets/images/logos/Warning.png"
                                                 alt="">
                                            <h3 class="text-color-black mt-1">
                                                {{ $t('No Alerts & Notifications') }}
                                            </h3>
                                        </div>
                                    </div>
                                </v-col> -->
                            </v-row>
                            <v-row class="pa-4">
                                <v-col cols="12">
                                    <p style="font-size: 13px;margin-bottom: 2px;"
                                       class="text-black font-weight-semibold">
                                        {{ $t('Marketing Communication') }}
                                    </p>
                                </v-col>

                                <v-col v-for="(item, index) in options"
                                       :key="index"
                                       cols="12"
                                       lg="4"
                                       md="6"
                                       sm="12">

                                    <v-card class="pa-4">
                                        <v-row>
                                            <v-col cols="12"
                                                   sm="12"
                                                   md="12"
                                                   lg="10">
                                                <p style="font-size: 13px;margin-bottom: 2px;"
                                                   class="text-black font-weight-semibold">
                                                    {{ $t(item.title) }}
                                                </p>
                                                <p style="font-size: 12px;color: rgba(64, 81, 131, 1);">
                                                    {{ $t(item.subtitle) }}
                                                </p>
                                            </v-col>
                                            <v-col cols="12"
                                                   sm="12"
                                                   md="12"
                                                   lg="2"
                                                   dir="ltr"
                                                   class="d-flex justify-center">
                                                <div
                                                     :class="{ 'pt-4 ml-3': selectedLanguage == 'ar', 'pt-4 mr-3': selectedLanguage == 'en' }">
                                                    <label class="switch">
                                                        <input v-model="item.value"
                                                               v-on:change="addUserPrefers()"
                                                               type="checkbox">
                                                        dsds<span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col cols="12"
                                       lg="4"
                                       md="6"
                                       sm="12">

                                    <v-card class="pa-4">
                                        <v-row>
                                            <v-col cols="12"
                                                   sm="12"
                                                   md="12"
                                                   lg="10">
                                                <p style="font-size: 13px;margin-bottom: 2px;"
                                                   class="text-black font-weight-semibold">
                                                    {{ $t('Language preference') }}
                                                </p>
                                                <p style="font-size: 12px;color: rgba(64, 81, 131, 1);">
                                                    {{ $t('LanguageSubtitle') }}
                                                </p>
                                            </v-col>
                                            <v-col cols="12"
                                                   sm="12"
                                                   md="12"
                                                   lg="2"
                                                   dir="ltr"
                                                   class="d-flex justify-center">
                                                <div
                                                     :class="{ 'pt-4 ml-3': selectedLanguage == 'ar', 'pt-4 mr-3': selectedLanguage == 'en' }">
                                                    <label class="switch-lang">
                                                        <input v-model="languagePref"
                                                               v-on:change="addUserPrefers()"
                                                               type="checkbox">
                                                        <span class="slider-lang round">
                                                            <span style="color: white;font-size: 11px;left: 6px;position: relative;bottom: 1px;"
                                                                  v-if="languagePref">English</span>
                                                            <span style="color: white;font-size: 11px;left: 30px;position: relative;bottom: 1px;"
                                                                  v-if="!languagePref">العربية</span>
                                                        </span>
                                                    </label>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>

                            </v-row>
                        </div>

                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import AppHeader from '../test/AppHeader.vue';
import store from '@/store';
import moment from 'moment'
import { DeviceUUID } from 'device-uuid'
import sharedServices from '@/services/shared-services';
import router from '@/router';
import soapServices from '@/services/soap-services'
import BackAction from '@/@core/BackAction.vue';
import ManageCardCounts from '../card-details/ManageCardCounts.vue';
import ManageCardDetailsBox from '../card-details/ManageCardDetailsBox.vue';
//  import ManageCardActions from './ManageCardActions.vue';

export default {
    components: {
        AppHeader,
        ManageCardCounts,
        ManageCardDetailsBox,
        BackAction
        // ManageCardActions
    },

    data: () => ({
        text: 'Back to Card Details',
        output: false,
        checked: false,
        checked1: true,
        email: false,
        phone: false,
        sms: false,
        languagePref: false,
        selectedLanguage: 'en',
        isReadOnly: true,
        records: [],
        options: [
            { title: 'Email', subtitle: 'EmailSubtitle', value: false },
            { title: 'SMS', subtitle: 'SMSSubtitle', value: false },
            { title: 'Phone', subtitle: 'PhoneSubtitle', value: false },
            // { title: 'Language preference', subtitle: 'LanguageSubtitle', value: false }
        ],
        selectedCard: null

    }),
    created()
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        })
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang == 'ar')
        {
            this.selectedLanguage = lang;
        }
        else
        {
            this.selectedLanguage = lang;
        }
        this.getNotifications()

        this.selectedCard = store.get('card/details');

        this.getUserPrefers();
    },

    methods: {
        getTitle(record)
        {

            if (record.NotificationID == 'TXNB')
            {
                return 'all_transactions';
            } else if (record.NotificationID == 'PTRC')
            {
                return 'payment_received';
            } else if (record.NotificationID == 'PTRM')
            {
                return 'payment_reminder';
            } else if (record.NotificationID == 'ACSS')
            {
                return 'account_snapshot';
            } else if (record.NotificationID == 'BGEN')
            {
                return 'statement_generation'
            } else if (record.NotificationID == 'TXLS')
            {
                return 'list_transaction';

            }
        },
        getSubTitle(record)
        {
            if (record.NotificationID == 'TXNB')
            {
                return 'alert_me_transaction_performed';
            } else if (record.NotificationID == 'PTRC')
            {
                return 'get_confirmation_payment_received';
            } else if (record.NotificationID == 'PTRM')
            {
                return 'receive_reminder_in_advance';
            } else if (record.NotificationID == 'ACSS')
            {
                return 'get_quick_view_important_account_info';
            } else if (record.NotificationID == 'BGEN')
            {
                return 'receive_notification_statement_ready';
            } else if (record.NotificationID == 'TXLS')
            {
                return 'email_once_week_listing_activities';
            }
        },
        back()
        {
            router.push('/card-detail')
        },
        getNotifications()
        {
            let obj = {
                correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
                messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
                sysId: new DeviceUUID().get(),
                sysAuth: store.get('requestKeys/accessToken'),
                requestorID: localStorage.getItem('userName'),
                idSeed: sharedServices.getIdSeed(),
            }
            soapServices.AlertsNotificationsRepo(obj).then(soapResp =>
            {
                // console.log(soapResp);
                if (soapResp.Result.ErrorCode == "000")
                {

                    // console.log('this.records', this.records);
                    if (soapResp?.Body.Record[0].NotificationID)
                    {
                        this.records = soapResp?.Body.Record;
                    }
                    else
                    {
                        this.records = [];
                    }

                    window.scrollTo(0, 0);
                    let alertMessage = soapResp.Result?.ErrorDesc
                    this.alertMessage = alertMessage
                    this.alertType = 'success'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }
                else
                {
                    let alertMessage = soapResp.Result?.ErrorDesc
                    this.alertMessage = alertMessage
                    this.alertType = 'error'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }
            })
        },
        getUserPrefers()
        {
            sharedServices.getUserPrefers().then(prefs =>
            {
                if (prefs.status == 200)
                {
                    this.options[0].value = prefs?.data?.promotionalEmail == 'Y' ? true : false;
                    this.options[1].value = prefs?.data?.promotionalSMS == 'Y' ? true : false;
                    this.options[2].value = prefs?.data?.promotionalCalls == 'Y' ? true : false;

                    this.languagePref = prefs?.data?.userLanguage == 'ARABIC' ? false : true;
                }
                else
                {
                    this.alertMessage = 'Error'
                    this.alertType = 'error'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }
                // promotionalCalls: "1"
                // promotionalEmail: "0"
                // promotionalSMS: "1"
                // userLanguage: "english"
            });
        },
        addUserPrefers()
        {
            let data = {
                userLanguage: this.languagePref == true ? 'english' : 'arabic',
                promotionalSMS: this.options[1].value == false ? '0' : '1',
                promotionalEmail: this.options[0].value == false ? '0' : '1',
                promotionalCalls: this.options[2].value == false ? '0' : '1'
            }
            sharedServices.addUserPrefers(data).then(prefs =>
            {
                if (prefs.status == 200)
                {
                    this.getUserPrefers();
                }
                else
                {
                    this.alertMessage = 'Error'
                    this.alertType = 'error'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }
                // promotionalCalls: "1"
                // promotionalEmail: "0"
                // promotionalSMS: "1"
                // userLanguage: "english"
            });
        }

    }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.v-application.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

.cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 146px;
    height: 1.2em;
    white-space: nowrap;
}

.backbtn {
    margin-left: -24px;
}

.area {
    background-color: white;
}

.v-application .info--text {
    color: #006FCF !important;
    caret-color: #006FCF;
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
}

.switch-lang {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 26px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch-lang input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider-lang {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 111, 207, 1);
    -webkit-transition: .4s;
    transition: .4s;
}

.slider-lang:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 5px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider-lang {
    background-color: rgba(0, 111, 207, 1);
}

input:focus+.slider-lang {
    box-shadow: 0 0 1px rgba(0, 111, 207, 1);
}

input:checked+.slider-lang:before {
    -webkit-transform: translateX(42px);
    -ms-transform: translateX(42px);
    transform: translateX(42px);
}

/* Rounded sliders */
.slider-lang.round {
    border-radius: 34px;
}

.slider-lang.round:before {
    border-radius: 50%;
}


.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 5px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: rgba(0, 111, 207, 1);
}

input:focus+.slider {
    box-shadow: 0 0 1px rgba(0, 111, 207, 1);
}

input:checked+.slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
