<template>
    <v-card class="mt-7 balance-card-background mx-13 pa-3 pb-7">
        <span v-if="!isSupplementarySelected"
              class="mx-3 text-black font-weight-semibold"
              style="font-size: 14px;">{{ $t('Primary Card') }}</span>
        <v-row class="pa-3"
               align="stretch"
               fill-height>
            <v-col cols="12"
                   lg="4"
                   v-for="(cards, mainidx) in cardBoxDetails"
                   :key="mainidx"
                   align-self="stretch"
                   :class="{ 'bdr-bottom': cardBoxDetails.length > 3 && mainidx < cardBoxDetails.length - 3, 'pt-10 pb-10': mainidx > 2 }">
                <v-row v-for="(card, idx) in cards"
                       :key="idx">
                    <v-col cols="12"
                           lg="12"
                           v-if="card.isPrimary">
                        <PrimaryCard :cards="cards"
                                     :card="card"
                                     @manage="manageCard($event)"
                                     :isSupplementarySelected="isSupplementarySelected"
                                     :singleCard="singleCard"></PrimaryCard>
                    </v-col>



                    <v-col lg="12"
                           
                           v-if="!card.isPrimary">
                        <!-- <v-row class="mt-1">
                            <v-col cols="12"> -->
                        <div class="ml-0 text-black font-weight-bold"
                             style="font-size: 14px;">{{ $t('Supplementary Cards') }}</div>
                        <!-- </v-col>
                        </v-row> -->
                        <SupplimentaryCard :cards="cards"
                                     :card="card"
                                     :selectedLang="selectedLanguage"
                                     @manage="manageCard($event)"
                                     :isSupplementarySelected="isSupplementarySelected"
                                     :singleCard="singleCard"></SupplimentaryCard>
                    </v-col>
                </v-row>
            </v-col>
            <v-col v-if="singleCard"
                   cols="12"
                   lg="8">
                <v-card class="pa-3 white-card-background">
                    <v-row class="mt-1">
                        <BackAction :text="text"
                                    :output="output"
                                    @back="back" />
                    </v-row>
                    <ManageCardActions :card="selectedCardToManage"></ManageCardActions>
                </v-card>
            </v-col>
        </v-row>

    </v-card>
</template>

<script>
import store from '@/store';
import router from '@/router';
import BackAction from '@/@core/BackAction.vue'
import ManageCardActions from './ManageCardActions.vue';

import PrimaryCard from './PrimaryCard.vue';
import SupplimentaryCard from './SupplimentaryCard.vue';

export default {
    components: {
        ManageCardActions,
        BackAction,
        PrimaryCard,
        SupplimentaryCard
    },
    data: () => ({
        accountCards: [],
        cardBoxDetails: [],
        singleCard: false,
        selectedCardToManage: {},
        activeCard: null,
        selectedLanguage: 'en',
        text: 'Back to Cards',
        output: true,
        isSupplementarySelected: false,
    }),
    created()
    {
        
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload
            }
        })
    },
    mounted()
    {
        store.set('card/supplementory', null)
        this.accountCards = store.get('cardStore/accountCards');
        this.cardBoxDetails = this.accountCards;

        let isManageCardAvailable = store.get('manageCard/details');
        if (isManageCardAvailable)
        {
            this.singleCard = true;
            this.selectedCardToManage = isManageCardAvailable;
            this.cardBoxDetails = [this.selectedCardToManage];
        }
    },
    onDeactivated()
    {
        store.set('manageCard/details', null);
    },
    unmounted()
    {
        store.set('manageCard/details', null);
    },
    beforeUnmount()
    {
        store.set('manageCard/details', null);
    },

    methods: {
        selectCard()
        {
            router.push('/card-issue')
        },
        back(event)
        {
            this.singleCard = false;
            this.isSupplementarySelected = false
            this.cardBoxDetails = this.accountCards;
        },
        manageCard(event)
        {
            console.log('Event manage Card', event);
            this.isSupplementarySelected = event.isSupplementarySelected;
            if (event.isSupplementarySelected)
            {
                store.set('card/details', event.cards[0])
                this.singleCard = true;
                this.selectedCardToManage = [event.card];
                this.cardBoxDetails = [this.selectedCardToManage];
            }
            else
            {
                this.singleCard = true;
                this.selectedCardToManage = event.cards;
                this.cardBoxDetails = [event.cards];
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.v-application.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

.cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 146px;
    height: 1.2em;
    white-space: nowrap;
}

.manage-btn {
    padding: 9px 0px 10px;
}

.suspended {
    background: rgba(237, 28, 36, 0.15);
    color: #D92D20;
    width: 126px;
    font-size: 10px;
    padding: 2px 0px 2px;
    margin: 3px 0px -1px;
}


.img {
    height: 33px;
    margin-left: 10px;
}



.area {
    background-color: white;
}

.bdr-bottom {
    border-bottom: 1px solid lightgray;
}
@media (max-width: 500px) {
.mx-13 {
    margin-right: 23px !important;
    margin-left: 23px !important; 
}
}
</style>
;